import terms from "@/resource/terms/terms.en";
import privacyPolicy from "@/resource/privacyPolicy/privacyPolicy.en";
import responsible from "@/resource/responsibleGaming/responsible.en";
import goPlusTerms from "@/resource/goPlusTerms/goPlusTerms.en";

const brandName = "PLUS65";
const year = "2024";
const month = "September";

export default {
	common: {
		brandName: `${brandName}`,
		year: `${year}`,
		pageNotFound: "Page Not Found",
		userId: "User ID",
		dob: "Date of Birth",
		games: "Games",
		close: "Close",
		doNotShowAgain: "Do not show again for today",
		readAll: "Read All",
		announcement: "Announcement",
		live: "Live",
		download: "Download",
		username: "Name",
		fullName: "Full Name",
		asFullName: "Same As Your Bank Account Name",
		password: "Password",
		confirmPassword: "Confirm Password",
		currentPassword: "Current Password",
		newPassword: "New Password",
		copy: "Copy",
		copySuccess: "Copied successfully",
		copyFailed: "Failed to copy",
		search: "Search",
		numberOfResults: "{number} result(s) found",
		itemsPerPage: "Result(s) shown per page",
		submit: "Submit",
		update: "Update",
		pleaseLogin1: "Please",
		pleaseLogin2: "to get your username and password.",
		yes: "Yes",
		no: "No",
		requestTac: "Request TAC",
		verificationCode: "Verification Code",
		resendInSeconds: "Resend in {time} second(s)",
		phoneNumber: "Phone Number",
		EnterYourPhoneNumber: "Enter your Phone Number",
		EnterYourPassword: "Enter your Password",
		EnterYourFullName: "Enter your Full Name",
		RetypeYourPassword: "Retype your Password",
		referralCode: "Referral Code",
		optional: "Optional",
		termsConsent: {
			iAmOver18: "I'm over 18 years of age and I accept these",
			and: "and",
			iHaveReadAndAgreedToThe: "I have read and agreed to the",
		},
		nextStep: "Next Step",
		next: "Next",
		nextLevel: "Next Level",
		current: "Current",
		accountSetup: "Account Setup",
		tac: "TAC",
		done: "Done",
		back: "Back",
		resendTac: "Resend TAC",
		success: "Success",
		registerSuccess: "Your account has been created successfully!",
		on: "ON",
		off: "OFF",
		min: "Min",
		max: "Max",
		notice: "Important Notice",
		in: "In",
		out: "Out",
		startDate: "Start Date",
		endDate: "End Date",
		noData: "No data",
		provider: "Provider",
		selectProvider: "Select provider",
		facebook: "Facebook",
		whatsApp: "WhatsApp",
		telegram: "Telegram",
		email: "Email",
		line: "LINE",
		twitter: "Twitter",
		claim: "Claim",
		claimNow: "Claim Now",
		areYouSure: "Are you sure?",
		cannotChangeAgain: "You won't be able to change again after this.",
		youAreGoingToClaim: "You are going to claim this bonus.",
		welcomeToPLUS65: `Welcome to ${brandName}`,
		loginToContinue: `Log in to continue your experience with ${brandName} application`,
		ResetPassword: "Reset Password",
		ResetPasswordDesc: "Kindly type in your phone number to reset your password",
		EnterNewPassword: "Please enter your new password",
		more: "More",
		selectDate: "Select Date",
		selectCategory: "Select Category",
		today: "Today",
		last24Hours: "Last 24 hours",
		lastNDays: "Last {days} days",
		lastUpdateOn: "Updated On {updateTime}",
		light: "Light",
		dark: "Dark",
		system: "System",
		note: "Note",
		bankInfoNote: "Only one bank account can be linked to your account",
		scanToObtainReferralCode: "Please scan QR code to register",
		welcomeMember: "Welcome, {membername}",
		registerWelcome:
			"To become a member, simply scan the QR code provided by an existing member. Once scanned, follow the steps to complete your registration. Welcome aboard!",
		shareLinkText: `Join ${brandName} with Me & Get Cash Rewards!`,
		edit: "Edit",
		cancel: "Cancel",
		checkIn: "Check In",
		total: "Total",
		viewReferrals: "View Referrals",
		viewReceipt: "View Receipt",
		balance: "Balance",
		totalBalance: "Total Balance",
		totalEarnings: "Total Earnings",
		principalAmount: "Principal Amount",
		earnedInterest: "Earned Interest",
		nettDailyReturnRate: "Nett Daily Return Rate",
		nettWeeklyReturnRate: "Nett Weekly Return Rate",
		cashIn: "Cash In",
		cashOut: "Cash Out",
		totalCashOut: "Total Cash Out",
		recentTransactions: "Recent Transactions",
		recentPayouts: "Recent Payouts",
		viewAll: "View all",
		viewLess: "View less",
		goPlusWarning:
			"Your balance is close to the maximum limit: {max}. Once you have reached the limit, you will stop receiving earnings.",
		goPlusLimit:
			"Your balance has reached the maximum limit: {max}. Please cash out to continue receiving earnings.",
		pullDownToRefresh: "Pull down to refresh",
		releaseToRefresh: "Release to refresh",
		releaseToFullRefresh: "Release to full refresh",
		onLoading: "Loading...",
		copyright: `Copyright © ${year} ${brandName}. All Rights Reserved.`,
		termsAndConditions: "Terms & Conditions",
		FAQ: "FAQs",
		day: "day",
		week: "week",
		dailyPayout: "Daily Payout",
		weeklyPayout: "Weekly Payout",
		onlyRecordsUpToNDays: "Only records up to {days} day(s) available",
		cashInVia: "Cash In Via",
		cashOutTo: "Cash Out To",
		enterAmount: "Enter amount",
		enterAmountMin: "Enter amount (Min. {min})",
		maximum: "Maximum",
		minimum: "Minimum",
		transferableBalance: "Transferable balance",
		totalAmount: "Total amount",
		allAmountWillBeCashedOut: `All amount in your ${brandName}+ wallet wil be cashed out.`,
		question: "Q",
		answer: "A",
		addToHomeScreen: "Add to Home Screen",
		addToHomeScreenInfo: "Add to home screen and enjoy the immersive experience!",
		install: "Install",
		referralNow: "Refer Now!",
		interestInsufficientBalance: "You'll need a minimum transferable balance of {min} to make a cash in.",
		noBalanceToBeCashedOut: "No balance to be cashed out.",
		comingSoon: "Coming Soon",
		underMaintenance: "Under Maintenance",
		confirmDeposit: "Confirm Deposit",
		depositToLockedWallet:
			"Your deposit amount of {currency}{amount} will be credited into your Locked Wallet [{category}].",
		yourNewTurnover: "Your new turnover will be {amount}.",
		doYouWishToProceed: "Do you wish to proceed?",
		topup: "Top Up",
		loginRegister: "Login/Register",
		rank: "Rank",
		confirm: "Confirm",
		hot: "Hot",
		"turnover exist": "Turnover exists",
		"winover exist": "Winover exists",
		transferSummary: "Transfer Summary",
		transferAndPlay: "Transfer & Play",
		howDoesThisWork: "How does this work?",
		details: "Details",
		memberService: "Member Service",
		restricted: "Restricted",
		ticketsAvailable: "Tickets available",
		redirecting: "Redirecting",
		youAreBeingPaymentGateway: "You are now being redirected to the payment gateway...",
		unsupportedBrowser: "Unsupported browser",
		pleaseSwitchBrowser: `For optimal ${brandName} experience, please switch to a supported browser`,
		pleaseSelect: "--- Please Select ---",
		areYouNewHere: "Are you new here?",
		alreadyAMember: "Already a member?",
		maxPayout: "Max Payout: {maxPayout}",
	},
	scan: {
		ScanBarCodeOrQRCode: "Scan BarCode or QR Code",
		ScanFromGallery: "Scan From Gallery",
	},
	games: {
		viewMoreGames: "View More Games",
		speedBacarrat: "Speed Bacarrat",
		betNow: "Bet Now",
		playNow: "Play Now",
		play: "Play",
		instructions: "Instructions",
		instructionsDetails: "Please use the username and password provided to login.",
	},
	account: {
		mainWallet: "Main Wallet",
		lockedWallet: "Locked Wallet",
		depositNow: "Deposit Now",
		transferAmount: "Transfer Amount",
		promoCode: "Promo Code",
		promotion: "Promotion",
		selectPromotion: "Select promotion",
		bankDetails: "Bank Details",
		bankName: "Bank Name",
		accountHolderName: "Account Holder Name",
		accountName: "Account Name",
		accountNumber: "Account Number",
		selectBank: "Select bank",
		viewFullWallet: "View Full Wallet",
		general: "General",
		payment: "Payment",
		banking: "Banking",
		account: "Account",
		autoTransfer: "Main Wallet Auto Transfer",
		paymentMethod: "Payment Method",
		selectPaymentMethod: "Select payment method",
		depositAmount: "Deposit Amount",
		deposit: "Deposit",
		depositNotice: [
			{
				notice: "Please always check for our latest bank account details before making a deposit.",
			},
			{
				notice: "Depositor's Account Name must match with user’s registered full name.",
			},
			{
				notice: "Deposit using 3rd party accounts, cheques, bank drafts and credit cards are not allowed.",
			},
			{
				notice: "Deposit request should be submitted only after deposit transaction is successful.",
			},
		],
		withdrawalNotice: [
			{
				notice: "Withdrawal bank name must match with registered full name. Member unable to withdrawal to 3rd party bank account.",
			},
			{
				notice: "Please make sure the bank account name & bank account number is correct before submit the withdrawal.",
			},
			{
				notice: "Once you have successfully submitted your withdrawal form and once your fund is cleared in our account, just leave it to our team to process your transactions as speedy as possible. If the process take more than 10 minutes, let us know by contacting our Customer Service support. They will assist you 24/7 anytime.",
			},
		],
		depositChannel: "Deposit Channel",
		selectChannel: "Select channel",
		onlineTransfer: "Online Transfer",
		cashDepositMachine: "Cash Deposit Machine",
		atm: "ATM",
		overTheCounter: "Over The Counter",
		cheque: "Cheque",
		uploadReceipt: "Upload Receipt",
		minimumDeposit: "Minimum Deposit",
		minimumWithdrawal: "Minimum Withdrawal",
		minWithdrawal: "Min Withdrawal",
		maxWithdrawal: "Max Withdrawal",
		dailyLimit: "Daily Limit",
		withdrawalAmount: "Withdrawal Amount",
		availableBalance: "Available Balance",
		chipsBalance: "Chips Balance",
		transactionHistory: "Transaction History",
		transferFrom: "Transfer From",
		transferTo: "Transfer To",
		selectWallet: "Select wallet",
		mainWalletBalance: "Main Wallet Balance",
		restore: "Restore",
		winover: "Winover",
		winovererBalance: "Winover Balance",
		turnover: "Turnover",
		turnoverBalance: "Turnover Balance",
		referNow: "Refer Now",
		referralRewards: "Referral Rewards",
		youHave: "You have",
		connections: "connections",
		totalCashEarned: "Total cash earned",
		successfulReferrals: "Successful referrals",
		yourReferrals: "Your Referrals",
		referralStates: {
			registered: "Registered",
			depositSuccessful: "Deposit {amount} successful",
			claim: "Claim",
			claimed: "Claimed",
		},
		unclaimed: "Unclaimed",
		inProgress: "In progress",
		claimAll: "Claim All",
		allClaimed: "All claimed",
		bonusOngoing: "You have bonus in progress",
		requirementNotMet: "Requirement not met",
		referralCode: "Referral Code",
		referralLink: "Referral Link",
		successfulRegistrations: "Successful registrations",
		shareToFriends: "Share to friends",
		referralTerms: "Terms and conditions apply to all referrals and bonuses.",
		referralInstructions:
			"Show this QR code to a friend, scan and complete registration to receive referral bonus.",
		minDepositRequired:
			"A minimum of {minDeposit} deposits is required to participate in the referral program offer.",
		birthdayBonus: "Birthday Bonus",
		birthdayConditionYearlyClaim: "Members can claim this bonus once per year.",
		birthdayConditionFirstDeposit: "This promotion can only be applied after member's first deposit.",
		birthdayConditionBirthdayMonth: "Members can claim their birthday bonus 15 days before or after birthdate.",
		birthdayConditionMemberDuration: `Members must have joined ${brandName} for at least one month.`,
		birthdayExample:
			"For example: a member's birthday falls on 16th of May, he/she is able to claim it between 1st May to 31st May. (15 days before or after)",
		birthdayNotice1: "Please make sure you have filled in your birthday at the ",
		birthdayNotice2: " page.",
		changingBirthdayTo: "Changing birthday to",
		minAmount: "Min amount: {min}",
		PLUS65Plus: `${brandName}+`,
		EarnDaily: "Earn > {earn}% Daily",
		BonusApplied: "Active Bonus",
		ReferEarn: "Refer & Earn",
		GoPlusBalance: `${brandName}+ Balance`,
		LockBalance: "Lock Balance",
		AddPaymentInfo: "Add Payment Info",
		NoApplyBonus: "No Promotions",
		ApplyBonus: "Apply Bonus",
		TargetTurnover: "Target Turnover",
		GoApply: "Go Apply >>>",
		personalInformation: "Personal Information",
		showBalance: "Show Balance",
		maxPayoutWarning:
			"Please note that your previous bonus claim is subject to a maximum payout of {currency}{amount}. This transfer will include the full amount from the vendor, but any amount exceeding {currency}{amount} will be forfeited.",
		bonusComplete: "Bonus Complete",
		youHaveCompletedYourBonus:
			"Congratulations! You've completed your bonus, you may now restore your winnings to Main Wallet to continue.",
	},
	checkIn: {
		DailyCheckIn: "Daily Check In",
		CheckInToday: "Check In Today and Get",
		CheckInSlogan: "Remember to check-in daily to collect all rewards!",
		RecheckInLimit: "Recheck-in Availability",
		Day: "Day {day}",
		minigames: "Mini Game Ticket x {amount}",
		adjustment: "Free Credit {currency} {amount}",
		addonbonus: "Add-on Bonus {amount}%",
		bonus: "Bonus {currency} {amount} Turnover x {rollover}",
		empty: " - ",
		chips: "Chips x {amount}",
		backdateCheckIn: "Backdate Check-in",
		recheckIn: "Recheck-in",
		m_adjustment: "FC",
		checkIn: "Check-in",
		AddOnBonusTitle: "Add On Bonus",
		MissedDays: "Missed days",
		days: "day(s)",
		Credit_1: "Daily Deposit Target",
		Credit_2: "Daily Turnover Target",
		bonusAppliedForAddonBonus: "Promotion applied for add-on bonus",
		checkInNotice: [
			{
				notice: `This event is available for ${brandName} members only.`,
			},
			{
				notice: "Free credit will be credited to your wallet immediately upon successful Check-in, with x1 turnover requirement.",
			},
			{
				notice: "Bonus amount will be credited to your locked wallet with their specific turnover requirement.",
			},
			{
				notice: "Any bets resulting in void, tie, cancelled, or made on opposite sides with the same outcome will not be counted as a valid turnover.",
			},
			{
				notice: `${brandName} is the sole arbiter of this event, and its decision is final.`,
			},
		],
		errorMsg: {
			E1001: "Available Recheck-in(s) has been used up.",
			E1002: "Recheck-in is not available.",
			E1003: "Today's Recheck-in has been completed.",
			E1004: "Mission Target has not been achieved yet.",
			E1005: "Turnover exists.",
			E1006: "Please contact our customer service.",
			E1007: "Today's check-in has been completed.",
			E1008: "Today's check-in has been completed.",
			E1009: "Please contact our customer service.",
			E1010: "Please contact our customer service.",
			E1011: "Please contact our customer service.",
			E1012: "Please contact our customer service.",
			E9999: "Please contact our customer service.",
		},
	},
	inbox: {
		deleteInbox: "Delete Message",
	},
	bonusTypes: {
		Main: "All",
		SLOTS: "Slots Bonus",
		"LIVE CASINO": "Live Casino Bonus",
		FISHING: "Fishing Bonus",
		SPORTS: "Sports Bonus",
		COCKFIGHT: "Cockfight Bonus",
		LOTTERY: "4D Bonus",
		"FAST GAME": "Fast Games Bonus",
		"NEW MEMBER": "New Member",
		REFERRAL: "Referral Bonus",
		WELCOME: "Welcome Bonus",
		RESCUE: "Rescue Bonus",
		BIRTHDAY: "Birthday Bonus",
		LOCK: "Selected Vendors",
	},
	category: {
		none: "-",
		all: "All",
		sports: "Sports",
		casino: "Casino",
		livecasino: "Live Casino",
		fishing: "Fishing",
		slots: "Slots",
		special: "Special",
		autoDeposit: "Auto",
		manualDeposit: "Manual",
		eWallet: "eWallet",
		deposit: "Deposit",
		withdrawal: "Withdrawal",
		transfer: "Transfer",
		betHistory: "Bet History",
		bonus: "Bonus",
		adjustment: "Adjustment",
		loginPassword: "Login Password",
		gamePassword: "Game Password",
		lottery: "4D",
		cockfight: "Cockfight",
		minigames: "Mini Games",
		promotion: "Promotion",
		payment: "Payment",
		system: "System",
		notification: "Notifications",
		fastgame: "Fast Games",
		interest: `${brandName}+`,
		aboutus: "About Us",
		terms: "Terms & Conditions",
		privacypolicy: "Privacy Policy",
		responsiblegaming: "Responsible Gaming",
		ANDROID: "Android",
		IOS: "IOS",
		download: "Download",
		lock: "Selected Vendors",
	},
	footer: {
		gamingLicense: "Gaming License",
		followUs: "Follow Us",
		paymentMethod: "Payment Method",
		poweredBy: "Powered By",
		certification: "Certification",
		security: "Security",
		responsibleGaming: "Responsible Gaming",
		copyright: `Copyright © ${year} ${brandName}. All Rights Reserved {'|'} 18+`,
		gameLicensesDetails: `${brandName} Offers Wide Range Of Highest Quality Gaming Products To Our Players. Our Customer Support Team Is Available To Assist You 24 Hours A Day. All Personal Information Will Be Treated And Stored At The Strictest And Most Confidential Way.`,
	},
	route: {
		home: "Lobby",
		download: "Download",
		login: "Login",
		register: "Register",
		forgotpassword: "Forgot Password",
		slots: "Slots",
		casino: "Live Casino",
		fishing: "Fishing",
		sports: "Sports",
		promotion: "Promotions",
		aboutus: "About Us",
		contact: "Contact Us",
		terms: "Terms & Conditions",
		privacypolicy: "Privacy Policy",
		responsiblegaming: "Responsible Gaming",
		vip: "VIP",
		language: "Language",
		inbox: "Inbox",
		interchange: "Interchange",
		profile: "Profile",
		bankinfo: "Bank Info",
		referral: "Referral",
		bonus: "Bonus",
		deposit: "Deposit",
		transfer: "Transfer",
		withdrawal: "Withdrawal",
		statement: "Statement",
		changepassword: "Change Password",
		quicktransfer: "Quick Transfer",
		logout: "Logout",
		qrcode: "QR Code",
		scan: "Scan",
		myaccount: "Settings",
		setting: "Profile",
		checkin: "Check In",
		notfound: "Page Not Found",
		livechat: "Helpdesk",
		theme: "Theme",
		interest: `${brandName}+`,
		cashin: "Cash In",
		cashout: "Cash Out",
		fastgame: "Fast Games",
		notification: "Notifications",
		search: "Search",
	},
	language: {
		en: "English",
		ms: "Malay",
		zh: "中文",
	},
	validation: {
		required: "This field is required",
		minAmount: "Please enter a minimum amount of {minAmount}",
		maxAmount: "Please enter a maximum amount of {maxAmount}",
		minLength: "Please enter a minimum of {minLength} characters",
		maxLength: "Exceeded character limit: {maxLength}",
		numeric: "Please enter numbers only",
		fullNameInvalid:
			"Name format is invalid (only letters, spaces, and '-' are allowed; no consequtive spaces, name must not start or end with space)",
		passwordNotMatch: "Passwords do not match",
		pleaseAgree: "Please indicate that you have read and agreed to the Terms and Conditions and Privacy Policy",
		cannotTransferToSameWallet: "Cannot transfer to the same wallet",
		newPasswordMustBeDifferent: "New password must be different from old password",
	},
	placeholder: {
		characters: "{minLength} to {maxLength} characters",
	},
	toast: {
		fetchError: "Failed to fetch data, please contact our customer service. {error}",
		autoLogout: "Auto logout in 5 seconds.",
		sessionExpired: "Session expired. Please login again.",
		logoutSuccess: "Logout success.",
		launchFailed: "Launch game failed, please contact our customer service. {error}",
		"turnover exist": "Turnover exists, balance will not be transferred.",
		"winover exist": "Winover exists, balance will not be transferred.",
		transferSuccess: "Transfer success.",
		transferFailed: "Transfer failed. {error}",
		tacSuccess: "TAC request success. Please check your phone.",
		tacFailed: "TAC request failed. Please try again later.",
		tacVerificationFailed: "TAC verification failed.",
		submitSuccess: "Submit successful.",
		submitFailed: "Submit failed. Please try again later. {error}",
		loginFailed: "Invalid Username/Password",
		loginSuccess: "Login success.",
		registerSuccess: "Account registered successfully.",
		resetPasswordSuccess: "Submit successful. Please check your mobile phone to get the reset password link.",
		autoTransferEnabled:
			"You have enabled Auto Transfer. Please allow up to 1 minute for the system to update your preferences. Thank You.",
		autoTransferDisabled:
			"You have disabled Auto Transfer. Please allow up to 1 minute for the system to update your preferences. Thank You.",
		changePasswordSuccess: "Password has been changed successfully.",
		changePasswordFailed: "Change password failed {error}",
		checkInSuccess: "Check-in success.",
		checkInFailed: "Check-in failed. {error}",
		BookmarkSuccess: "Bookmark Added",
		UnBookmarkSuccess: "Bookmark Removed",
		MarkAsReadSuccess: "Marked As Read",
		MarkAsUnreadSuccess: "Marked As Unread",
		RemoveSuccess: "Message Deleted",
		BookmarkFailed: "Error: Failed to Bookmark Added",
		UnBookmarkFailed: "Error: Failed to Bookmark Removed",
		MarkAsReadFailed: "Error: Failed to Marked As Read",
		MarkAsUnreadFailed: "Error: Failed to Marked As Unread",
		RemoveFailed: "Error: Failed to Deleted",
		ticketSuccess: "Transaction ID : {id} has been approved.",
		ticketReject: "Transaction ID : {id} has been rejected. Please contact our Customer Service.",
		claimSuccessful: "Claim successful.",
		claimUnsuccessful: "Claim unsuccessful. {error}",
		dailyMissionIncomplete_1: "Your daily mission is incomplete, please fulfill your deposit target",
		dailyMissionIncomplete_2: "Your daily mission is incomplete, please fulfill your turnover target",
		newInboxReceived: "You have new inbox message!",
		checkInNotStartYet: "Sorry, the check-in event has not started yet. Please check back later.",
		depositFailed: "Deposit Failed. Please try again later. {error}",
		AllowOneDeposit: "Previous deposit ticket is still in progress",
		AllowOneActiveWithdrawal: "Previous withdrawal ticket is still in progress",
		miniGameisOpened: "MiniGame is already opened in another tab",
		newVersion: "A new version is released, click here to update",
		popUpBlocked: "Pop-up Blocker is enabled, unable to launch game.",
		urlNotFound: "URL not found.",
		InsufficientBalanceWithdrawal: "Insufficient balance to withdraw",
		GameMaintance: "Game is under maintenance",
		launchGameError: "Launch game error. Failed to fetch URL.",
		referral: {
			errorMsg: {
				"521": "Referral ID not found.",
				"522": "Referral bonus not found.",
				"523": "Unable to claim Referral Bonus. Requirement not met.",
				"524": "Unable to claim Referral Bonus. Turnover exist.",
				"525": "Unable to claim Referral Bonus. Winover exist.",
				"526": "No Referral Bonus to be claimed.",
				"529": "Claim failed. Please contact customer service.",
			},
		},
		notificationGranted: "You have allowed the permission of notification feature.",
		notificationDenied:
			"Permission for the notification feature has been denied. Please enable it in your device settings.",
		notificationAllow: "Your notification feature has been activated.",
		notificationNotAllow: "Your notification feature has been deactivated.",
		notificationNotSupport: "Your browser does not support notification feature",
		cameraDenied: "Camera permission has been denied. Please enable it in your device settings.",
		cameraNotSupport: "Your browser or device does not support this feature",
		accountAccessed: "You will be logged out due to multiple login attempts detected. Please log in again.",
		toastOffline: "It seems like you are offline. Please check your network connection.",
		vendorRestricted:
			"The bonus that you claimed does not support this game. Please reselect the game specified in the promotion terms.",
		claimRegisterBonusUnsuccessful: "Unable to claim Register Bonus. Requirement not met.",
		claimReferralBonusUnsuccessful: "Unable to claim Referral Bonus. Requirement not met.",
		gameUnderMaintenance: "Game is under maintenance.",
		bonusOngoing: "You have active bonus in progress. Click here to view the running bonus.",
	},
	notification: {
		granted: "Activated",
		denied: "Deactivated",
		default: "Activate Now",
		notSupport: "Not Supported",
	},
	about: {
		about1: `${brandName} Online Casino offers many of the most popular casino games you will find in some of the most famous casinos around the world. Enjoy the safe and secure online casino experience, where you can play online slots, Poker, Baccarat, Roulette, Blackjack, and many more online casino games!`,
		title2: `WELCOME TO THE TOP & TRUSTED ONLINE CASINO SINGAPORE: ${brandName}`,
		about2: `${brandName}, Singapore Best Online Casino Singapore, offers an unparalleled selection of casino games, including a wide array of slot Singapore options. Every player can join us to experience the most rewarding casino games, with many featuring slot free credit promotions to get you started.\n\n Entertain yourself with the best casino games, all available with quality sound and captivating themes. Try your luck at our online casino games, including popular slot e wallet options for convenient and secure transactions. Join us now and get a chance to win exciting rewards. Play all your favorite slot games like 918kiss, mega888, pragmatic, jili and more.\n\n Feeling lucky today? Don't waste any more time thinking – start your gaming adventure with the best Online Betting Singapore platform. ${brandName} casino is waiting for you to play anytime, anywhere, offering a seamless experience whether you're on desktop or mobile.\n\n Experience the convenience of slot e wallet transactions, allowing for quick deposits and withdrawals. Our slot free credit promotions give you more chances to win big without risking your own funds. As a leading online casino Singapore provider, ${brandName} is committed to delivering an exceptional gaming experience that combines variety, security, and generous bonuses.\n\n Don't miss out on the action – join ${brandName} today and discover why we're the top choice for Singaporean players looking for the best in online casino entertainment!`,
		aboutUs: `Introducing ${brandName}, the premier online gambling platform launched in ${month} ${year}, in Singapore! ${brandName} is designed for every gaming enthusiast, offering an extensive range of exciting casino games, including GreatBlue, Safari Heat, Dolphin Reef, Iceland, Thai Boxing, Blackjack, Roulette, and Baccarat. Whether you're into classic table games or thrilling slots, we've got you covered.
					\nWith ${brandName}, you can enjoy your favorite games anytime, anywhere—whether on your Android/iOS phone, tablet, or desktop. Our platform is built with a user-friendly and simple interface, ensuring that even beginners can easily navigate and start playing right away. Players must be over 18 years old to join, in compliance with legal regulations.
					\nExperience the thrill of betting as if you were at Genting Highlands Sky Casino, right from the comfort of your home. Our highly secured platform prioritizes your privacy and data protection, offering unparalleled security and preventing any risk of data leakage. Safety is our top priority, so you can focus on enjoying the game.
					\n${brandName} also stands out with unique features that other platforms don't offer, such as our VIP system. The more you top up, the more rewards you'll enjoy, giving you access to exclusive perks and bonuses.
					\nOn top of that, you can play mini-games to earn free rewards daily! With a wide variety of game categories, including Slots, Live Casino, Fishing, Sports, Esports, and Mini Games, ${brandName} ensures there's something for every type of player.
					\nVisit the ${brandName} website today and dive into a world of excitement and rewards!`,
	},
	tutorial: {
		step1Title: "Join",
		step1Desc: "You can create the account speedily and free.",
		step2Title: "Deposit",
		step2Desc: "You can select the deposit option that you want.",
		step3Title: "Transfer",
		step3Desc: "You will need to transfer into your game's wallet from the account",
		step4Title: "Withdraw",
		step4Desc: "You can withdraw the winnings and transfer it to your account.",
	},
	vip: {
		enhanceYourGaming: "Enhance Your Gaming Adventure:\nStep Into the Premier VIP Lounge Casino.",
		experiencePrivilege: `EXPERIENCE THE PRIVILEGES OF A ${brandName} VIP.`,
		vipIntro: `With the new VIP Program, loyal members can get even more when playing in ${brandName}. It is more than just incentives, we offer exclusive promotions for VIPs only and bonus plays with the best returns.\n\nIn addition to the exclusivity, VIP members are our top priority. From depositing and withdrawal to any game issues, all VIP requests are first on our list.`,
		vipMemberLevel: "VIP Member Level",
		firstDeposit: "First Deposit",
		minDeposit: "Minimum Deposit",
		vipPrivileges: "VIP Privileges",
		vipBenefits: "VIP Benefits",
		upgradeBonus: "Tier Upgrade Bonus",
		birthdayBonus: "Birthday Bonus",
		dailyRescueBonus: "Daily Rescue Bonus",
		weeklyRescueBonus: "Weekly Rescue Bonus",
		accountManager: "Designated Account Manager",
		specialRebate: "Special Rebate",
		slotsRebate: "Slots Rebate",
		liveCasinoRebate: "Live Casino Rebate",
		sportsRebate: "Sports Rebate",
		fishingRebate: "Fishing Rebate",
		upgradeRequirement: "Tier Upgrade Requirement",
		minDepositWithinAMonth: "Minimum Deposit Within a month",
		retentionRequirement: "Tier Retention Requirement",
		lifetimeDeposit: "Accumulated Deposit",
		membershipRenewal: "Membership Renewal",
		lifetime: "Lifetime",
		once: "Once",
		always: "Always",
		daily: "Daily",
		weekly: "Weekly",
		monthly: "Monthly",
		quarterly: "Quarterly",
		"half yearly": "Half Yearly",
		yearly: "Yearly",
		vipExclusive: "VIP Exclusive",
		compulsory: "Identity Verification is Compulsory for VIP Upgrade",
		vipStatus: "Current VIP Status",
		increaseBalanceText: "Deposit now to unlock new VIP tier!",
		amountToReach: "Amount required to reach",
		currentProgress: "Current progress",
		welcomeBack: "Welcome back! Your loyalty and excitement are highly valued.",
		totalDeposit: "Total deposit",
		tnc: [
			{
				term: "For the first time upgrade, members are required to provide all the necessary documents for verification purposes. After the verification is complete, the membership status will be updated into the system within 3 working days.\n\nThe verification steps are as follows:\n\ni. Verify Profile Details (Full name, Phone number and Email Address)\nii. Verify Game Account Activity (Betting Style, Deposit & Withdraw Activity)\n\nImportant Note: Should there be any suspicious or abnormal activities during the 3-day verification period, the management reserves the right to reject the qualification to upgrade.",
			},
			{
				term: "VIP Privilege can be enjoyed immediately after upgrading to the next tier level and is valid for 3 months based on the highest tier achieved.\n\nImportant Note: In order to retain your membership status, members are required to achieve the same requirement within a single quarter.",
			},
			{
				term: `All VIP status upgrade invitations will be sent out by ${brandName} VIP Department.`,
			},
			{
				term: `${brandName} reserves the right to remove members' account VIP Privilege, and to downgrade the VIP levels or even terminate any accounts it deems to have any suspicious or abnormal activities at any time without any prior notice.\n\nImportant Note: Every membership status subject to downgrade based on the account activities.`,
			},
			{
				term: `All ${brandName} management decisions are final.`,
			},
		],
		faq: [
			{
				q: "How to become a VIP member?",
				a: "You will need to deposit at least RM10,000 within a single quarter (1 months) to become a Silver Member.",
			},
			{
				q: "How many levels are there for VIP program?",
				a: "There are a total of 6 levels. The VIP Program consists of Bronze, Silver, Gold, Platinum, Diamond and Royal tier levels.",
			},
			{
				q: "How do I get upgraded to the next level ?",
				a: "You will have to deposit the minimum amount required by each tier into your account. For example, if you are currently a Bronze Member, you will have to deposit at least RM10,000 to become a Silver Member, and from Silver Member, you will need to deposit at least another RM40,000 within 30 days to become a Gold Member.",
			},
			{
				q: "How do I retain my VIP membership status?",
				a: "To retain your member tier status for the following year, you will need to deposit the required amount by the last quarter before the expiration date. For example, if your Gold Member status is valid from January - December, you will need to achieve a RM25,000 deposit by the final quarter of the year (October) to retain your existing level for the following year. However, Bronze and Silver level membership status is valid for lifetime.",
			},
			{
				q: "Is there an annual or monthly fees to be part of the VIP members?",
				a: "No. This program was created to promote all loyal members who actively patronage our gaming experience since registered date.",
			},
		],
		nextTierProgress: "Next Tier Progression",
		choosePlan: "Choose your VIP Plan",
		upgradeNow: "Upgrade Now",
		upgrading: "Congratulations, you will be upgraded soon",
		currentTier: "Current tier",
	},
	error: {
		InvalidWithdrawalAmount: "Invalid withdrawal amount",
		ReachDailyLimit: "Daily limit exceeded",
		TurnoverExceeded: "Turnover exceeded",
		WithdrawalFailed: "Withdrawal failed, please contact Customer Support.",
		MemberNameExist: "Member name exists, please contact Customer Support.",
	},
	addMessages: {
		ios1: "1. On iOS browser, open Share menu",
		ios2: '2. Tap on "Add to Home Screen" button',
		android: `1. Open browser parameters
  2. Tap on "Add to homescreen"`,
		windows: {
			chrome: "Click on (+) button to the right of your navigation bar.",
			firefox: `1. Resize your browser so you can see your desktop
  2. Drag and drop the (i) button left of your navigation bar to your desktop`,
		},
		macos: {
			chrome: "Click on (+) button to the right of your navigation bar.",
			safari: `1. Resize your browser so you can see your desktop
  2. Drag & drop the earth icon left of your notification bar to your desktop`,
			firefox: `1. Resize your browser so you can see your desktop
  2. Drag and drop the (i) button left of your navigation bar to your desktop`,
		},
		others: "Looks like your browser doesn't support add to homescreen natively. Feel free to update/change your browser.",
	},
	terms,
	privacyPolicy,
	responsible,
	goPlusTerms,

	// ================= STATEMENT =================

	statement: {
		id: "ID",
		method: "Method",
		amount: "Amount",
		date: "Date",
		status: "Status",
		receipt: "Receipt",
		transactionType: "Type",
		vendorFrom: "From",
		vendorTo: "To",
		betId: "Bet ID",
		gameName: "Game",
		turnover: "Turnover",
		winOrLoss: "Win/loss",
		bonusName: "Bonus",
		bonusAmount: "Amount",
		group: "Group",
		targetAmount: "Target amount",
		New: "New",
		Success: "Success",
		Processing: "Processing",
		Rejected: "Rejected",
		RUNNING: "IN PROGRESS",
		COMPLETED: "COMPLETED",
		CANCELLED: "CANCELLED",
		SLOTS: "Slots Bonus",
		"LIVE CASINO": "Live Casino Bonus",
		FISHING: "Fishing Bonus",
		SPORTS: "Sports Bonus",
		COCKFIGHT: "Cockfight Bonus",
		LOTTERY: "4D Bonus",
		"FAST GAME": "Fast Games Bonus",
		"NEW MEMBER": "New Member",
		REFERRAL: "Referral Bonus",
		cashIn: "Cash In",
		cashOut: "Cash Out (Principle)",
		cashOutLock: "Cash Out (Interest)",
		MAIN: "Main Wallet",
		Interest: `${brandName}+`,
		LOCK: "Locked Wallet",

		ReferralAdj: "Referral Bonus",
		RegBonus: "Register Bonus",
		DepositAdj: "Deposit Adjustment",
		WithdrawalAdj: "Withdrawal Adjustment",
		RebateAdj: "Rebate",
		MiniGamesAdj: "Mini Game Bonus",
		RescueAdj: "Rescue Bonus",
		BirthdayAdj: "Birthday Bonus",
		RankUpAdj: "Rank Up Bonus",
		CheckInAdj: "Event Free Credit",
		ChipDepositAdj: "Chip Deposit Adjustment",
		ChipWithdrawalAdj: "Chip Withdrawal Adjustment",
		"BO Deposit": "BO Deposit",
		AutoDeposit: "Auto Deposit",
		DepositReferral: "Referral Deposit",
		ManualDeposit: "Manual Deposit",
		Withdrawal: "Withdrawal",
		DAILY: "Daily",
		WEEKLY: "Weekly",
	},
};
