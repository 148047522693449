<template>
	<div class="languageContainer">
		<CustomDropdown
			:floatLeft="true"
			:toggleByHover="!isMobile"
			@open="isDropdownOpen = true"
			@close="isDropdownOpen = false"
			ref="languageDropdown"
		>
			<template #toggle>
				<div class="languageToggle cursorPointer" tabindex="0">
					<img class="languageIcon" :src="imgUrlDomain + languageImgPath[locale]" alt="" />
				</div>
			</template>
			<template #dropdown>
				<template v-for="language in languages" :key="language.id">
					<CustomDropdownItem @click="changeLanguage(language.code)">
						<img
							class="languageIcon"
							:class="{ active: locale == language.code }"
							:src="imgUrlDomain + languageImgPath[language.code]"
							alt=""
						/>
						<div class="languageDropdownText" :class="{ active: locale == language.code }">
							{{ t(`language.${language.code}`) }}
						</div>
					</CustomDropdownItem>
				</template>
			</template>
		</CustomDropdown>
	</div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { languages } from "@/composables/useCulture";
import { useIsMobile } from "@/composables/useMobile";
import store from "@/store/index";
import CustomDropdown from "@/components/dynamic/CustomDropdown.vue";
import CustomDropdownItem from "@/components/dynamic/CustomDropdownItem.vue";

const { t, locale } = useI18n();
const isMobile = useIsMobile();

const imgUrlDomain = process.env.VUE_APP_Media_Domain;
const languageImgPath = {
	en: "plus65/assets/images/NavBar/en.svg",
	zh: "plus65/assets/images/NavBar/zh.svg",
	ms: "plus65/assets/images/NavBar/ms.svg",
};

const changeLanguage = (language) => {
	locale.value = language;
	localStorage.setItem("locale", language);
	toggleDropdown();
};

const isDropdownOpen = ref(false);

const languageDropdown = ref(null);
const toggleDropdown = async () => {
	languageDropdown.value?.toggleDropdown();
};
</script>

<style scoped lang="sass">
.languageToggle
	position: relative
	display: flex
	align-items: center
	height: 100%
	font-size: 1.05em
	font-weight: var(--font-medium)
	overflow: hidden
	&::after
		position: absolute
		content: ""
		width: 3.5rem
		height: 4px
		// background: var(--button-accent-background)
		bottom: 0
		left: 50%
		transform: translate(-50%, 100%)
		border-radius: 4px 4px 0 0
		transition: 0.2s
	&:hover
		color: var(--color-accent)
		&::after
			translate: -50%
	.languageIcon
		width: 2rem
		height: 2rem
		border: 2px solid var(--border-accent)
.languageIcon
	width: 1.6rem
	height: 1.6rem
	border-radius: 50%
	&.active
		outline: 2px solid var(--border-accent)
// .dropdownArrow
// 	font-size: 0.9em
// 	transition: 0.2s
// 	&.open
// 		transform: rotate(-180deg)
.languageDropdownText
	font-weight: var(--font-medium)
	&.active
		color: var(--color-accent)
</style>
